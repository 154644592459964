div#commentsArticle, 
div#commentsForm {
  margin: 6em 0 2em 0;
  padding: 1em 0;
  border-top: 1px dotted color('medium-grey', $colors-greyscale);
}

div#commentsArticle h2, 
div#commentsForm h2 {
  font-size: 1.4em;
  /*color: color('brand-accent-1');*/
}

div#commentsArticle p.com_Name {
  padding-left: 1.5em;
  background: url('/img/static/icon-user_single_small.png') 0 0 no-repeat;
}

div#commentsForm {
  padding: 1em 0;
}


div#commentsForm form {
  padding: 1em 0 0 2em;
  border: 1px solid color('medium-light-grey', $colors-greyscale);
  background: #f5f5f5;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

div#commentsForm .frmElement{
  clear: none;
  margin-bottom: 2em;
}

div#commentsForm .frmElement label {
  line-height: 1.3;
  margin-bottom: .25em;
  text-align: left;
  width: 29em;
}

div#commentsForm.form div.frmInputText input, 
div#commentsForm.form textarea {
  width: 30em;
}

div#commentsForm.form span.fieldComment {
  margin: .25em 0 0 0;
}

div#commentsForm.form .textRequired p {
  margin-left: 0;
}

div#commentsForm.form div.frmSubmit {
  margin-left: 0;
  padding-right: 2em;
  text-align: right;
}

div#commentsArticle.all { 
  border-top: 0 none;
  margin-top: 0;
  padding-top: 0;
}

div#commentsArticle div.item {
  border-top: 1px dotted color('medium-grey', $colors-greyscale);
  margin-top: 2em;
  padding-top: 2em;
}

div#commentsArticle div.item.first {
  border-top: 0 none;
  margin-top: 0;
  padding-top: 0;
}
