#navTop {
  background: #014784 url('../img/static/bg_navTop.gif') 0 0 repeat-x;

  a {
    font-family: 'OpenSansSemiBold', arial, sans-serif;
    display: block;
    float: left;
    font-size: 1.2em;
    text-decoration: none;
    color: color('white');
    padding: .75em 1.8em;
    text-align: center;
    border-right: 1px solid color('white');

    &.nav_off:hover, 
    &.nav_on {
      background: #c30404 url('../img/static/bg_navTop_on.gif') 0 0 repeat-x;
    }
  }
}
