a {
  &.pdf {
    background: url('../img/static/acrobat_24.gif') 0 0 no-repeat;
    display: block;
    margin: 1em 0;
    padding: .2em 0 .5em 2.5em;
  }

  &.pdfFliesstext {
    background: url('../img/static/acrobat_24.gif') 0 0 no-repeat;
    display: inline;
    margin: 0;
    padding: .2em 0 .5em 2.5em;
  }

  &.intern {
    background: url('/img/static/arrow_right_10x8.png') right .4em no-repeat;
    color: color('brand-primary');
    display: block;
    float: right;
    font: 1em/1.2em 'OpenSansSemiBold', arial, sans-serif;
    margin: 0;
    padding: 0 1.2em 0 0;
  }
}

span {
  &.bildFliesstext {
    display: block;
    float: left;
    margin-bottom: 1em;
    margin-right: 1em;
  }
}

.toLeft {
  float: left;
}

.toRight {
  float: right;
}

.txtLeft {
  text-align: left;
}

.txtRight {
  text-align: right;
}

.txtCenter {
  text-align: center;
}

.clear {
  clear: both;
}

.clearLeft {
  clear: left;
}

.clearRight {
  clear: right;
}

.toFocus {
  &:focus {
    background-color: #ddecff;
  }
}

.noCursor {
  cursor: default;
}

.rot, 
.pflicht {
  color: color('warn');
}

.c6 {
  color: color('medium-dark-grey', $colors-greyscale);
}

.c9 {
  color: color('medium-grey', $colors-greyscale);
}

.cC {
  color: color('medium-light-grey', $colors-greyscale);
}

.p110 {
  font-size: 1.1em;
  line-height: 1.4em;
}

.nowrap {
  white-space: nowrap;
}

.mp3 {
  background: transparent url('../img/static/icon-audio.png') no-repeat 0 0;
  display: block;
  margin: 1em 0;
  padding: 0 0 .5em 2.5em;
}
