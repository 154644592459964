div#form {
  margin-top: 0;
}

div#form fieldset {
  margin-bottom: 2em;
}

div#form fieldset.cc, 
div#form fieldset.hinweis {
  margin-left: 9.6em;
}

div#form fieldset.frmSubmit {
  margin-left: 21.4em;
}

div#form legend {
  display: none;
}

div#form div.frmGroup,
.form div.frmElement {
  clear: left;
  margin-bottom: 1em;
}

div#form div.innerGroup {
  float: left;
  margin-right: 1em;
}

div#form input, 
div#form select, 
div#form textarea, 
div#form label,
.form input, 
.form select, 
.form textarea, 
.form label {
  font-family: arial, verdana, sans-serif;
  font-size: 1.3em;
}

div#form label, 
div#form p.label,
.form label, 
.form p.label {
  color: color('medium-dark-grey', $colors-greyscale);
  cursor: pointer;
}

div#form div.frmInputText label, 
div#form div.frmTextarea label, 
div#form div.frmCheckbox label, 
div#form div.frmRadio p.label,
.form div.frmInputText label, 
.form div.frmTextarea label, 
.form div.frmCheckbox label, 
.form div.frmRadio p.label {
  display: block;
  float: left;
  margin: .2em 1em 0 0;
}

div#form div.frmInputText label, 
div#form div.frmTextarea label, 
div#form div.frmRadio p.label, 
.form div.frmInputText label, 
.form div.frmTextarea label, 
.form div.frmRadio p.label {
  width: 15.5em;
  text-align: right;
}

.form div.com_Email label {
  margin-top: -.25em;
}

div#form div.frmInputText input, 
div#form textarea,
.form div.frmInputText input, 
.form textarea {
  width: 27em;
  padding: .1em;
}

div#form textarea, 
.form textarea {
  height: 20em;
}

div#form div.frmInputText input.frmPlz {
  width: 6em;
}

div#form div.frmInputText input.frmOrt {
  width: 19.6em;
}

div#form div.frmCheckbox {
  margin-left: 11.5em;
}
div#form div.frmCheckbox div.input{
  float: left;
}

div#form div.frmCheckbox label {
  width: 26em;
}

div#form div.frmCheckbox.frmCC p.label {
  display: none;
}

div#form div.frmText {
  margin-left: 12em;
}

.form div.frmSubmit {
  margin-left: 12em;
}

div#form div.frmInputText.warning label, 
div#form div.frmTextarea.warning label, 
div#form div.frmRadio.warning p.label {
  color: color('warn');
  font-weight: bold;
}

div#form div#submit-message {
  font-size: 1.2em;
  line-height: 1.5em;
  margin-bottom: 2em;
  background: #ffc;
  border: 1px solid color('brand-accent-1');
  padding: 1em;
  color: color('brand-accent-1');
  font-weight: bold;
}

div#form label {
  background: none;
}

div#form span.error-message {
  border: 1px solid color('warn');
  /*display: none;*/
}

.form span.fieldComment {
  display: block;
  font-size: 1.2em;
  margin: .25em 0 2em 1em;
  color: color('medium-dark-grey', $colors-greyscale);
}

.textRequired p {
  color: color('medium-dark-grey', $colors-greyscale);
  margin-left: 9em;
}

.required {
  color: color('warn');
}
