/* 
    http://hugogiraudel.com/2014/03/03/another-sass-button-library/
*/

// Configuration
$btn-name: 'button' !default;
$btn-hover: darken 10% !default;
$btn-border:  darken 10% !default; // Set to false for no border
$btn-size-ratio: 1.2 !default;
$btn-schemes: (
  default: #7f8c8d,
  submit: color('brand-primary'),
  success: #27ae60,
  danger:  #c0392b,
  warning: #f1c40f,
  info:    #2980b9
) !default;

// Color helper 
// 1. Background-color
// 2. On hover
// 3. Border-color
@mixin button-color($color) {
  $everything-okay: true;
  
  $func_name: nth($btn-hover, 1);
  
  // Making sure $color is a color
  @if type-of($color) != color {
    @warn "`#{$color}` is not a color for `button-color`";
    $everything-okay: false;  
  }
  
  // Making sure $btn-hover and $btn-border are 2 items long
  @if length($btn-hover) != 2 
   or length($btn-border) != 2 {
    @warn "Both `$btn-hover` and `$btn-border` should be two items long for `button-color`.";
    $everything-okay: false;  
  }
  
  // Making sure first items from $btn-hover and $btn-border are valid functions
  @if not function-exists($func_name) 
   or not function-exists(nth($btn-border, 1)) {
    @warn "Either `#{$func_name}` or `#{nth($btn-border, 1)}` is not a valid function for `button-color`.";
    $everything-okay: false;  
  }
  
  // Making sure second items from $btn-hover and $btn-border are percentages
  @if type-of(nth($btn-hover, 2)) != number 
   or type-of(nth($btn-border, 2)) != number {
    @warn "Either `#{nth($btn-hover, 2)}` or `#{nth($btn-border, 2)}` is not a valid percentage for `button-color`.";
    $everything-okay: false;  
  }
  
  // If there is no mistake
  @if $everything-okay == true {
    //@debug $func_name;
    background-color: $color; // 1
  
    &:hover,
    &:active { // 2
      // Sass 4.x kompatibel
      // alt: background: call(nth($btn-hover, 1), $color, nth($btn-hover, 2));
      background: call(get-function($func_name), $color, nth($btn-hover, 2));
    }  
  
    @if $btn-border != false { // 3
      // Sass 4.x kompatibel:
      // alt: border-color: call(nth($btn-border, 1), $color, nth($btn-border, 2));
      border-color: call(get-function($func_name), $color, nth($btn-border, 2));
    }
  }
}

// Default class
// 1. Border or not border?
// 2. Large modifier
// 3. Small modifier
// 4. Bold modifier
// 5. Block modifier
// 6. Uppercase modifier
// 7. Color themes modifier
.#{$btn-name} {
  // Default styles
  padding: .25em .75em;
  margin-bottom: 1em;
  color: #fff;
  transition: background .15s;
  box-shadow: inset 0 1px rgba(255, 255, 255, .2);
  border-radius: .15em;
  border: if($btn-border != false, 1px solid, none); // 1
  
  // Modifiers
  &--large { // 2
    font-size: 1em * $btn-size-ratio;
  }
  
  &--small { // 3
    font-size: 1em / $btn-size-ratio;
  }

  &--bold { // 4
    font-weight: bold;
  }
  
  &--block { // 5
    display: block;
    width: 100%;
  }  
  
  &--upper { // 6
    text-transform: uppercase;
  }
  
  @each $key, $value in $btn-schemes { // 7
    &--#{$key} {
      @include button-color($value);
    }
  }
}