.float-wrap {
  &.petition {
    @include border-radius(.5em 0 .5em .5em);
    background: color('white');
    border: 2em solid color('light-grey', $colors-greyscale);
    padding: 2em;
    width: 45em;

    .p110 {
      color: color('dark-grey', $colors-greyscale);
      display: block;
      text-align: justify;
    }
  }
}

//.float-wrap.petition.thanx {
//  border-right: 0;
//  -webkit-border-bottom-right-radius: 0;
//  -khtml-border-bottom-right-radius: 0;
//  -moz-border-radius-bottomright: 0;
//  border-bottom-right-radius: 0;
//}

.left {
  &.petition {
    &.thanx {
      background-color: color('white');
      padding: 2em;
    }
  }
}

.right {
  &.petition {
    @include border-radius(0 .5em .5em 0);
    background: color('light-grey', $colors-greyscale);
    border: 2em solid color('light-grey', $colors-greyscale);
    border-left: 0;
    padding: 0;
    width: 34.5em;

    &.thanx {
      @include border-radius(0 0 0 .5em);
      border-left: 2em solid color('light-grey', $colors-greyscale);
    }
  }
}

.form-small.petition,
.petition.unterstuetzerListe {
  @include border-radius(.5em);
  background: color('white');
  border: 1px solid color('medium-grey', $colors-greyscale);
  margin-bottom: 1.8em;
  padding: 2em 0 1em 2em;
}

.petition {
  &.unterstuetzerListe {
    border: 1px solid color('medium-light-grey', $colors-greyscale);
    margin-bottom: 0;
    max-height: 82.2em;
    overflow-y: scroll;
    padding: 2em;
  }

  &.unterstuetzerListe-small {
    height: auto;
    overflow-y: scroll;
  }
}

.form-small {
  .frmElement {
    clear: none;
    position: relative;

    &.frmCheckbox {
      &.pet_Public {
        z-index: 1000;

        input {
          margin-left: -1.4em;
          margin-right: .6em;
        }
      }

      &.pet_Public label {
        float: none;
        line-height: 1.4em;
        margin-left: 1.8em;
      }
    }
  }

  .frmInputText {
    input {
      width: 22.6em;

      &.pet_PLZ {
        width: 4em;
      }

      &.pet_Ort {
        width: 17.2em;
      }
    }

    label {
      margin: 0 0 .2em;
      text-align: left;

      &.pet_PLZ {
        width: 4em;
      }
    }

    &.pet_PLZ {
      float: left;
      width: 7em;
      z-index: 1000;
    }
  }

  .textRequired {
    p {
      font-size: 1.1em;
      margin-left: 0;
    }
  }

  .frmSubmit {
    margin-left: 0;
    padding-right: 2em;
    text-align: right;
  }
}

.errMsg-pointer {
  background: url('/img/static/errMsg-pointer-right.png') no-repeat scroll right 1em transparent;
  height: 4em;
  left: -4.5em;
  padding-right: 7px;
  position: absolute;
  top: 1.5em;

  .errMsg {
    height: 2.1em;
    margin: 0;
    padding: .4em .5em;
    width: 2.3em;
  }
}

.errMsg-pointer-ort {
  background: url('/img/static/errMsg-pointer-left.png') no-repeat scroll left 1em transparent;
  left: 3.7em;
  padding-left: 7px;
}

.errMsg {
  @include border-radius(.3em);
  background: color('black', $colors-greyscale);
  color: color('white');
  cursor: pointer;
  margin: 0 0 2em 0;
  opacity: .66;
  overflow: hidden;
  padding: .6em .7em;
}

.errMsg-mark {
  color: color('white');
  background: url('/img/static/warndreieck.png') no-repeat scroll .3em .3em transparent;
  font-size: 1.3em;
  padding-left: 2.7em;
}

ol {
  &.petitionSupportList {
    padding-left: 3.2em;
  }
}
