/*
  AnythingSlider v1.7+ Default (base) theme
  By Chris Coyier: http://css-tricks.com
  with major improvements by Doug Neiner: http://pixelgraphics.us/
  based on work by Remy Sharp: http://jqueryfordesigners.com/
*/

/*****************************
  SET DEFAULT DIMENSIONS HERE
 *****************************/
/* change the ID to match your slider */
#slider {
  width: 675px;
  height: 275px;
  list-style: none;
  /* Prevent FOUC (see FAQ page) and keep things readable if javascript is disabled */
  overflow-y: auto;
  overflow-x: hidden;
  background: color('white');
}

/*****************
  SET COLORS HERE
 *****************/
/**** Default state (no keyboard focus) ****/
/* slider window - top & bottom borders, default state */
div.anythingSlider .anythingWindow {
  /*border-top: 3px solid #777;
  border-bottom: 3px solid #777;*/
}

/* Navigation buttons, default state */
div.anythingSlider .anythingControls ul a.cur, 
div.anythingSlider .anythingControls ul a {
  background: color('brand-primary');
  color: color('white');
}

/* start-stop button, stopped, default state */
div.anythingSlider .start-stop {
  background-color: #014784;
  color: color('white');
}

/* start-stop button, playing, default state */
div.anythingSlider .start-stop.playing {
  background-color: color('brand-accent-1');
}

/* start-stop button, default hovered text color (when visible) */
div.anythingSlider .start-stop:hover, div.anythingSlider .start-stop.hover {
  color: color('brand-primary');
}

/**** Active State (slider has keyboard focus) ****/
/* slider window - top & bottom borders, active state */
div.anythingSlider.activeSlider .anythingWindow {
  /*border-color: #7C9127;*/
}

/* Navigation buttons, active state */
div.anythingSlider.activeSlider .anythingControls ul a.cur {
  background-color: #014784;
}

/* start-stop button, stopped, active state */
div.anythingSlider .start-stop {
  background: transparent;
  /*color: color('white');*/
}

/* start-stop button, playing, active state */
div.anythingSlider .start-stop.playing {
  background: transparent;
  /*background-color: #d00;*/
}

/* start-stop button, active slider hovered text color (when visible) */
div.anythingSlider .start-stop:hover, div.anythingSlider .start-stop.hover {
  color: color('white');
}

/***********************
  COMMON SLIDER STYLING
 ***********************/
/* Overall Wrapper: 45px right & left padding for the arrows, 28px @ bottom for navigation */
div.anythingSlider {
  display: block;
  margin: 0 auto;
  overflow: visible !important; /* needed for Opera and Safari */
  position: relative;
  padding: 1.5em 0 0 0;
}

/* anythingSlider viewport window */
div.anythingSlider .anythingWindow {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

/* anythingSlider base (original element) */
.anythingBase {
  background: transparent;
  list-style: none;
  position: absolute;
  overflow: visible !important;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

/* all panels inside the slider */
.anythingBase .panel {
  background: transparent;
  display: block;
  overflow: hidden;
  float: left;
  padding: 0;
  margin: 0;
}

.anythingBase .panel.vertical {
  float: none;
}

/* Navigation Arrows */
div.anythingSlider .arrow {
  top: 50%;
  position: absolute;
  display: block;
}

div.anythingSlider .arrow a {
  display: block;
  height: 140px;
  margin: -70px 0 0 0; /* half height of image */
  width: 45px;
  text-align: center;
  outline: 0;
}

/* hide text, target the span so IE7 doesn't text-indent the link */
div.anythingSlider .arrow a span { display: block; visibility: hidden; }
/* back arrow */
div.anythingSlider .back { left: 0; }
div.anythingSlider .back a { background-position: left top; }
div.anythingSlider .back a:hover, div.anythingSlider .back a.hover { background-position: left -140px; }
div.anythingSlider .back.disabled { display: none; } /* disabled arrows, hide or reduce opacity: opacity: .5; filter: alpha(opacity=50); */
/* forward arrow */
div.anythingSlider .forward { right: 0; }
div.anythingSlider .forward a { background-position: right top; }
div.anythingSlider .forward a:hover, div.anythingSlider .forward a.hover { background-position: right -140px; }
div.anythingSlider .forward.disabled { display: none; } /* disabled arrows, hide or reduce opacity: opacity: .5; filter: alpha(opacity=50); */

/* Navigation Links */
div.anythingSlider .anythingControls { outline: 0; display: none; background: #ccc; height: 24px;}
div.anythingSlider .anythingControls ul { margin: 0; padding: 0; float: right;}
div.anythingSlider .anythingControls ul li { display: inline; }
div.anythingSlider .anythingControls ul a {
  font-size: 12px;
  display: inline-block;
  text-decoration: none;
  padding: 1px 9px 4px 9px;
  height: 18px;
  margin: 0 0 0 1px;
  text-align: center;
  outline: 0;
}

div.anythingSlider .anythingControls ul span.prependTitleThemes {
  display: block;
  float: left;
  font-size: 11px;
  padding: 0 1.2em 0 0;
  margin: 2px 8px 0 0;
  background: url('/img_static/layout/arrow_right_10x8_666.png') right 0.5em no-repeat;
}

div.anythingSlider .anythingControls ul a:hover {
  background: #014784
}
/* Navigation size window */
div.anythingSlider .anythingControls .anythingNavWindow { overflow: hidden; float: left; }

/* slider autoplay right-to-left, reverse order of nav links to look better */
div.anythingSlider.rtl .anythingControls ul a { float: right; } /* reverse order of nav links */
div.anythingSlider.rtl .anythingControls ul { float: left; }    /* move nav link group to left */
div.anythingSlider.rtl .anythingWindow {
  direction: ltr;
  unicode-bidi: bidi-override;
}

/* div.anythingSlider.rtl .start-stop { float: right; } */ /* move start/stop button - in case you want to switch sides */

/* Autoplay Start/Stop button */
div.anythingSlider .start-stop {
  padding: 0;
  width: 20px;
  height: 25px;
  text-align: center;
  text-decoration: none;
  float: right;
  z-index: 100;
  outline: 0;
}

/* hide cell shading on hover - makes the button appear to come forward */
div.anythingSlider .start-stop:hover, div.anythingSlider .start-stop.hover { background-image: none; }

/* probably not necessary, but added just in case */
div.anythingSlider, div.anythingSlider .anythingWindow, div.anythingSlider .anythingControls ul a, div.anythingSlider .arrow a, div.anythingSlider .start-stop {
  transition-duration: 0;
  -o-transition-duration: 0;
  -moz-transition-duration: 0;
  -webkit-transition-duration: 0;
}

div.anythingSlider a.start-stop {
  margin: -27.3em -2em 0 0;
}

#slider div.content {
  padding-top: 1em;
}

#slider div.content div.thumb {
  float: left;
  width: 11.5em;
  padding-top: 2em;
}

#slider div.content div.thumb div.imgWrapper {
  float: right;
  /*height: 13em;*/
  overflow: hidden;
  background: url("/img_static/layout/bg_shadowImgNews.png") no-repeat scroll right bottom transparent;
  background-size: 100% auto;
  padding: 0 0.6em 0.6em 0;
}

#slider div.content div.thumb .linkImg {
  display: block;
  background-repeat: no-repeat;
  border: 1px solid color('medium-light-grey', $colors-greyscale);
}

#slider div.content div.thumb div.img img {
  border: 1px solid color('medium-light-grey', $colors-greyscale);
}

#slider div.content div.text {
  margin-left: 13em;
}

#slider div.content div.text.noMargin {
  margin-left: 3em;
}

#slider div.content div.text p {
  font-size: 13px;
  line-height: 21px;
  padding-right: 30px;
  margin: 0;
  color: #444;
}

#slider div.content div.text p a {
  text-decoration: none;
  color: #444;
}

#slider div.content.veranstaltung div.text h3 {
  font-family: 'OpenSansRegular', arial, sans-serif;
}

#slider div.content.veranstaltung div.text p a {
  font-family: 'OpenSansRegular', arial, sans-serif;
  font-size: 1.2em;
  color: #014784;
}

#slider div.content div.text p.date {
  font-size: 11px;
  padding-right: 0;
}

#slider div.content div.text h3 {
  font: 24px/1.3em 'OpenSansLight', arial, sans-serif;
  color: color('brand-accent-1');
  padding: 0 30px 0 0;
  margin: 0 0 0.3em 0;
}

#slider div.content div.text h4 {
  font: 18px/1.3em 'OpenSansLight', arial, sans-serif;
  color: color('brand-accent-1');
  padding: 0 30px 0 0;
  margin: 0 0 0.3em 0;
}

#slider div.content div.text h3 a,
#slider div.content div.text h4 a {
  text-decoration: none;
  color: color('brand-accent-1');
}

#slider div.content div.text a.intern {
  margin-right: 1.5em;
  background-position: right 0.3em;
  text-decoration: none;
}
