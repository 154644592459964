
// Colors
// ======
// For project colors. 
//
// Approach: 
// ---------
// @LandonSchropp's approach of naming color variables.
// - http://davidwalsh.name/sass-color-variables-dont-suck
// - http://chir.ag/projects/name-that-color/
//
// Usage:
// ------
// .test {
//   background-color: color(shamrock);
// }
//
// OPTIONAL:
// --------
// Sublime gutter: https://sublime.wbond.net/packages/Gutter%20Color 
// See the colors you used in the gutter (Awesome if you use sublime text)
// ==========

$colors-greyscale: (
  'black': #000,
  'dark-grey': #333,
  'medium-dark-grey': #666,
  'medium-grey': #999,
  'medium-light-grey': #ccc,
  'light-grey': #eee,
  'super-light-grey': #f5f5f5,
);

$colors: (
  'brand-primary': #69c, //BiProNv-Blau
  'brand-secondary': #369, //BiProNv-Dunkelblau
  'brand-accent-1': #c00, //BiProNv-Rot
  'default-text-color': #333,
  'default-link-color': #69c,
  'dark-blue': #036,
  'white': #fff,
  'warn': #c00
);
