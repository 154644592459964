.row {
  &.efa {
    .center {
      margin-left: 0;
      padding-left: 2em;
    }
  }
}

iframe {
  &.efa {
    border: 0;
    margin: 0;
    padding: 0 0 1em;
    width: 65em;
  }
}
