body {
  background: color('white') url('/img/static/bgAsphalt.jpg');
  color: color('black', $colors-greyscale);
  font-family: 'OpenSansRegular', arial, verdana, sans-serif;
  font-size: 62.5%;/*IE*/
}

html>body {
  font-size: 10px;/*andere*/
}

.bottom {
  margin: 2em auto;
  position: relative;
}

.bottom div#BiTel p {
  color: color('medium-dark-grey', $colors-greyscale);
  float: left;
  font-size: 1em;
  line-height: 1.3em;
  padding-right: 1em;
  text-align: right;
  width: 15em;
}

.bannerMobielefeld {
  height: 71px;
  position: absolute;
  right: -4px;
  top: -71px;
  width: 235px;
}

// ------------------------------ globale Klassen ------------------------------
#newsGalleryTitle {
  background-image: url('/img/static/fancybox_2/fancy_title_over.png');
  padding: 10px;
}

#newsGalleryTitle p {
  color: color('white');
  font-size: 1.1em;
  line-height: 1.4em;
  padding: 0;
}

#newsGalleryTitle p.pagina {
  font-size: 10px;
  text-align: right;
}

// ------------------------------ Sitemap --------------------------------------
ul#sitemap, 
ul#sitemap ul {
  list-style-image: url('../img/static/bg_nav_left_on.gif');
}

// ------------------------------ neu ------------------------------------------
.row {
  .left {
    float: left;
    width: 17em;
  }

  .center {
    background: color('white');
    margin-left: 17em;
    padding: 1.2em 1.4em 2.4em 2.4em;
  }
}

.innerContent.start {
  background: color('light-grey', $colors-greyscale);
  height: 32.4em;
  margin-bottom: 0;
  padding: 0;
  width: 71.5em;
}

hr.lineFooter {
  border: 0 none;
  border-top: 1px dotted color('medium-light-grey', $colors-greyscale);
  clear: both;
  display: block;
  margin: 1.5em 1.5em 0 0;
  padding: 0 0 1em 0;
}

// News-Übersicht
// -----------------------------------------------------------------------------
.row {
  &.news {
    background: color('white');
    
    &.headerNewsIndex,
    &.newsIndex {
      background: transparent;
    }

    &.headerNewsIndex {
      h1 {
        color: #014784;
        float: left;
        font: 18px/1.3em 'OpenSansLight', arial, sans-serif;
        margin-right: .25em;
      }

      form {
        float: left;
        margin-top: .4em;
      }

      div.pagination {
        float: right;
      }
    }

    .item {
      background: color('white');
      margin-bottom: 1em;
      padding: 1em 2em 2em 0;
    }

    .gallery {
      margin-bottom: 3em;
    }
  }
}

// News-Pagination
// -----------------------------------------------------------------------------
.newsIndex-Pagination-Link_Back, 
.newsIndex-Pagination-Link_Next {
  display: inline-block;
  font-size: 1.6em;
}

.newsIndex-Pagination-Link_Back {
  float: left;
  margin-right: .4em;
}

.newsIndex-Pagination-Link_Next {
  margin-left: .4em;
}

.newsIndex-Pagination-Link_Back::before {
  background-image: url('/img/static/icon-arrow-sprite.png');
  background-position: -1.4em -1.4em;
  background-repeat: no-repeat;
  background-size: auto 5em;
  content: '';
  display: inline-block;
  height: .9em;
  vertical-align: sub;
  width: 1.1em;
}

.newsIndex-Pagination-Link_Next::after {
  background-image: url('/img/static/icon-arrow-sprite.png');
  background-position: 0 -1.4em;
  background-repeat: no-repeat;
  background-size: auto 5em;
  content: '';
  display: inline-block;
  height: .9em;
  vertical-align: sub;
  width: 1.1em;
}

// left column News-Einzelseite
// -----------------------------------------------------------------------------
.row {
  &.news {
    .left {
      float: left;
      padding: 4.5em 0 0 2em;
      width: 15.5em;
    }

    .imageWrapper {
      clear: right;
      margin-bottom: 2em;

      .shadow {
        background: url('/img/static/bg_shadowImgNews.png') right bottom no-repeat;
        float: right;
        margin-bottom: .5em;
        padding: 0 .7em .6em 0;
        text-align: right;
      }

      a {
        &.img {
          background: color('white');
          border: 1px solid color('medium-light-grey', $colors-greyscale);
          display: block;
          height: 22.5em;
          overflow: hidden;
          width: 12em;
        }

        &.bildunterzeile {
          background: url('/img/static/zoom-in.png') top right no-repeat;
          clear: right;
          color: color('dark-grey', $colors-greyscale);
          display: block;
          font-size: 1.1em;
          line-height: 1.3em;
          margin-right: .5em;
          padding-right: 2em;
          text-align: right;
          text-decoration: none;
        }
      }
    }
  }
}

.row {
  &.news {
    .gallery {
      .imageWrapper a.img {
        height: 9em;
        width: 12em;
      }
    }

    &.newsIndex {
      .left  {
        width: 14em;
        padding: 3.4em 1em 0 0;

        .imageWrapper {
          .shadow {
            padding-right: .6em;
          }

          a.img {
            height: 16em;
            width: 10em;
          }
        }
      }
    }
  }
}

// center column
// -----------------------------------------------------------------------------
.row {
  &.news {
    .center {
      margin-left: 19em;
      padding-right: 2.4em;
      
      p.dateCategory {
        color: color('medium-dark-grey', $colors-greyscale);
        font-size: 1.2em;
        margin-bottom: .5em;

        span.categories {
          color: color('medium-grey', $colors-greyscale);
        }
      }

      h1 {
        font-family: 'OpenSansLight', arial, sans-serif;
        margin-bottom: .5em;
      }

      p.teaser {
        color: color('medium-dark-grey', $colors-greyscale);
        font-family: 'OpenSansItalic', arial, sans-serif;
        margin-bottom: 3em;
      }
    }

    &.veranstaltung .center p.teaser {
      color: #014784;
      font-family: 'OpenSansSemiBold', arial, sans-serif;
      font-size: 1.5em;
    }

    &.newsIndex {
      .center {
        margin-left: 16em;
        padding-right: 0;
        padding-top: 0;

        &.noMargin {
          margin-left: 1.5em;
        }

        p {
          color: color('dark-grey', $colors-greyscale);
          font-size: 13px;
          line-height: 21px;
          margin: 0;
          padding-right: 30px;

          a {
            text-decoration: none;
            color: color('dark-grey', $colors-greyscale);
          }
        }

        h3 {
          color: color('brand-accent-1');
          font: 24px/1.3em 'OpenSansLight', arial, sans-serif;
          margin: 0 0 .3em 0;
          padding: 0 30px 0 0;

          a {
            color: color('brand-accent-1');
            text-decoration: none;
          }
        }

        a.intern {
          background-position: right .3em;
          font-size: 1.2em;
          margin-right: 1.5em;
          text-decoration: none;
        }
      }

      div.item.veranstaltung {
        border: 1px solid color('medium-grey', $colors-greyscale);

        p.dateCategory {
          margin-bottom: .38em;
        }

        .center {
          h3 {
            font-family: 'OpenSansRegular', arial, sans-serif;
          }

          p a {
            color: #014784;
            font-size: 1.2em;
          }
        }
      }
    }

    .newsAlbum,
    .alignText .newsAlbum.gallery {
      margin: 4em 0 -2em -1em;
    }
    
    .newsAlbum.has-no-margin {
      margin-left: 0;
    }

    .newsAlbum {
      .imageWrapper {
        clear: none;
        float: left;
        height: 27em;
        margin-bottom: 2em;
        width: 14em;
      }
      
      &.amt4 {
        margin: 4em 0 -2em -2.4em;

        .imageWrapper {
          width: 15.75em;
        }
      }

      &.gallery {
        margin: 4em 0 -2em -15em;

        .imageWrapper {
          height: 9em;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
// thumbnail News-Artikel

.l-thumbnail {// alt: imageWrapper
  clear: none;
  float: left;
  margin-bottom: 3em;
  width: 14em;
  //height: 27em;

  .newsAlbum.amt4 & {
    width: 15.75em;
  }

  .newsAlbum.gallery & {
    width: 9em;
  }
  
  .left & {
    float: right;
  }
  
  .amt3 &,
  .amt5 &,
  .amt7 & {
    &:nth-child(4) {
      clear: left;
    }
  }
}

.l-thumbnail__media {
  float: left;//right
  margin-bottom: .5em;
  position: relative;

  img {
    height: auto;
    width: 12em;
  }
}

.l-thumbnail__media-overlay {
  background-color: transparent;
  //display: none;
  height: 95%;
  position: absolute;
  transition: background-color .3s;
  width: 94%;

  b {
    display: none;
  }

  .l-thumbnail__media:hover &, 
  .l-thumbnail__media:focus & {
    //display: block;
    background-color: rgba(color('brand-primary'), .8);

    b {
      color: color('white');
      display: block;
      font-size: 1.3rem;
      padding-top: 50%;
      text-align: center;
    }
  }
}

.c-thumbnail__media { //alt: .shadow
  background: url('/img/static/bg_shadowImgNews.png') right bottom no-repeat;
  padding: 0 .7em .6em 0;
  text-align: right;

  img {
    border: 1px solid color('medium-light-grey', $colors-greyscale);
  }
}

.l-thumbnail__caption { // alt: .bildunterzeile
  clear: left;
  display: block;
  margin-right: .9em;

  .newsAlbum.amt4 & {
    width: 9em;
  }
}

.c-thumbnail__caption {// alt: .bildunterzeile
  background: {
    repeat: no-repeat;
  }
  color: color('dark-grey', $colors-greyscale);
  font-size: 1.1em;
  line-height: 1.3;
  text-decoration: none;

  &:focus {
    background-color: color('brand-primary');
    color: color('white');
  }

  .c-thumbnail--zoom & {
    background: {
      image: url('/img/static/zoom-in.png');
      position: top right;
    }
    padding-right: 2em;
    text-align: right;
  }

  .c-thumbnail--link & {
    background: {
      image: url('../img/static/acrobat_24.gif');
      position: 0 0;      
    }

    //margin-left: .5em;
    padding-left: 2.8em;
    text-align: left;

    .amt4 & {
      margin-left: 2.8em;
    }
  }
}
// end thumbnail News-Artikel
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Fußnoten
.l-footnote {
  margin-top: 3em;

  & + .l-footnote {
    margin-top: 1em;
  }
}

.kommentare {
  .row {
    .item.footer {
      padding: 2em;
    }    
  }  
}

.news-Img_Top {
  width: 100%;
  height: auto;
}

.logo-JaZurStadtbahn_Header {
  height: 8em;
  left: 16.3em;
  position: absolute;
  text-align: center;
  top: -7.4em;
  width: 7em;
}

#slider div.content div.text .news_overline {
  font-family: 'OpenSansSemibold', arial, sans-serif;
  margin-bottom: .25em;
}
