.header {
  background: url('/img/static/bg_head_right.png') right 1.1em no-repeat;
  height: 11.3em;
  margin-top: .7em;
  overflow: hidden;

  .header__wrapper {
    background: url('/img/static/bg_head_left.png') 0 0 no-repeat;
    margin-right: 0;
    padding-left: 5px;
    width: 945px;
  }

  #spacerTop {
    display: none;
    height: 1.1em;
  }

  .logo_t {
    left: 1.2em;
    position: relative;
    top: -12.5em;
    width: 14.2em;
  }
}

.metalinks {
  margin-top: 1.1em;
  overflow: hidden;

  .shadowLeft, 
  .shadowRight {
    display: block;
    font-size: 1em;
    height: 2.4em;
    padding: 0;
    width: 5px;
  }

  .shadowLeft {
    background: url('/img/static/bg_metalinks_left.png') 0 0 no-repeat;
    float: none;
    width: 5px;
  }

  .shadowRight {
    background: url('/img/static/bg_metalinks_right.png') right top no-repeat;
    float: right;
  }

  .innerMetalinks {
    background: url('/img/static/bg_metalinks_inner.png') center top no-repeat;
    float: right;
    height: 2.4em;
    width: 93.5em;
  }

  a, 
  span {
    color: color('white');
    display: block;
    float: right;
    font-size: 1.1em;
    padding: .4em;
    text-decoration: none;
  }

  a {
    &.rss {
      background: url('../img/static/feed-icon-14x14.png') 0 .35em no-repeat;
      padding-left: 1.8em;
    }

    &.facebook {
      background: url('../img/static/logo_facebook.png') 0 .35em no-repeat;
      height: 1em;
      padding-left: 1em;
    }

    &.last {
      float: none;
    }
  }
}

.claim {
  background: #cedce8 url('/img/static/bg_claim_img.png') 0 0 no-repeat;
  height: 7.8em;
  padding: 0 0 1.2em 0;

  .claim__text {
    display: none;
    margin: 0;
    padding: 3.8em 23.4em 0 0;/*1.4em*/
    text-align: right;
  }

  .claim__category,
  p {
    color: color('white');
    font-family: 'OpenSansRegular', arial, sans-serif;
    font-size: 2.4em;
    margin: 0;
    padding: 0;
    text-align: right;
  }
}
