p, 
form, 
fieldset, 
img, 
h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
ul, 
li {
  border: 0 none;
  margin: 0;
  padding: 0;
}

h1 {
  color: color('brand-accent-1');
  font-family: 'OpenSansRegular', arial, sans-serif;
  font-size: 2.4em;
  font-weight: normal;
  margin-bottom: .875em;
}

h2 {
  color: color('black', $colors-greyscale);
  font-size: 1.8em;
  font-weight: normal;
  line-height: 1.33em;
  margin-bottom: 1em;
}

.center {
  h1 + h2 {
    color: color('brand-accent-1');
    margin-top: -.5em;
  }
}

h3 {
  color: color('dark-grey', $colors-greyscale);
  font-size: 1.5em;
  line-height: 1.6em;
  margin-bottom: .6em;
}

h4 {
  color: color('dark-grey', $colors-greyscale);
  font-size: 1.3em;
  line-height: 1.4em;
  margin-bottom: .2em;
}

p, 
ul, 
ol, 
.tbl {
  color: color('dark-grey', $colors-greyscale);
  font-size: 1.3em;
  line-height: 1.6em;
  margin-bottom: .6em;
}

small {
  font-size: 1em;
  line-height: 1.5;
}

ul {
  list-style-image: url('../img/static/li.gif');
  list-style-type: none;
  margin-left: 1.4em;
}

ul, 
ol {
  li {
    margin-bottom: .6em;
  }

  ul {
    font-size: 1em;
    margin-left: 1.5em;
  }
}

ol {
  ul {
    list-style-image: none;
    list-style-type: lower-alpha;

    li {
      margin-bottom: .3em;
    }
  }
}

a {
  color: color('brand-primary');
  text-decoration: underline;
}

.tbl {
  border-bottom: 1px solid color('medium-light-grey', $colors-greyscale);
  border-left: 1px solid color('medium-light-grey', $colors-greyscale);

  th, 
  td {
    border-right: 1px solid color('medium-light-grey', $colors-greyscale);
    border-top: 1px solid color('medium-light-grey', $colors-greyscale);
    padding: .5em;
  }
}
