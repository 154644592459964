.footer {
  .shadowLeft, 
  .shadowRight {
    display: block;
    font-size: 1em;
    height: 1.6em;
    padding: 0;
    width: 1.6em;
  }

  .shadowLeft {
    background: url('/img/static/bg_footer_left.png') 0 0 no-repeat;
    float: none;
  }

  .shadowRight {
    background: url('/img/static/bg_footer_right.png') right top no-repeat;
    float: right;
  }

  .innerFooter {
    background: url('/img/static/bg_footer_bottom.png') left bottom repeat-x;
    float: right;
    height: 1.6em;
    width: 92.3em;
  }

  .bottom_l {
    background: url('../img/static/bg_footer_l.gif') 0 0 no-repeat;
  }

  .bottom_r {
    background: url('../img/static/bg_footer_r.gif') right top no-repeat;
    float: right;
    width: 2em;
  }
}
