.fpSchnellauskunft {
  .frmElement {
    margin-bottom: .4em;

    &.submit {
      margin: 1.6em 1em 0 0;
      text-align: right;
    }
  }

  .frmGroup {
    &.radio {
      margin: 1.2em 0 .4em;
    }
  }

  input,
  label,
  select {
    font-size: 1.1em;
  }

  .inputText {
    label {
      display: block;
      margin: .6em 0 .2em;
    }

    input {
      border: 1px solid color('medium-light-grey', $colors-greyscale);
      padding: .2em;
      width: 13em;
    }
  }

  .select {
    label {
      display: block;
      margin: .2em 0;
    }

    select {
      border: 1px solid color('medium-light-grey', $colors-greyscale);
      padding: .1em;
      width: 4em;
    }
  }
}

.f_fpl-short__elm_inputText_date {
  position: relative;
}
