.float-wrap {
  &.kontakt {
    .row {
      background: color('white');
      padding-bottom: 2.4em;
      padding-right: 2.4em;
      padding-top: 1.2em;

      &.first {
        padding-left: 21.4em;
      }
    }
  }
}
