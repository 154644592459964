.boxRight {
  @include border-radius(5px);
  background-color: color('super-light-grey', $colors-greyscale);
  margin-bottom: 1em;
  margin-left: 1.5em;
  padding: 1em 0 1em 1em;

  h2 {
    color: color('brand-accent-1');
    font-size: 1.2em;
    margin: 0;
  }

  ul {
    font: 1.2em/1.3em 'OpenSansSemiBold', arial, sans-serif;
    margin: .5em 0 0 1.75em;
    padding: 0 .9em 0 0;

    li {
      &.active {
        a {
          color: #014784;
        }
      }
    }
  }

  &.start {
    height: 3.4em;
  }

  &.comments {
    .item {
      border-bottom: 1px dotted color('medium-grey', $colors-greyscale);
      margin: .5em .9em 1em 0;
      padding: 0 0 .75em;

      &.noBorder {
        border-bottom: 0 none;
        margin-bottom: 0;
      } 

      p {
        &.com_Name {
          background: url('/img/static/icon-user_single_small.png') 0 0 no-repeat;
          margin-bottom: .25em;
          padding-left: 1.5em;
        }

        &.com_Date {
          color: color('medium-dark-grey', $colors-greyscale);
          font-size: 11px;
          margin-bottom: .25em;
        }

        &.com_Text {
          font-size: 1.2em;
          margin-bottom: 0;

          a {
            color: color('dark-grey', $colors-greyscale);
            text-decoration: none;
          }
        }
      }
    }

    a {
      &.intern {
        font-size: 1.2em;
        margin-top: .25em;
        text-align: right;
        text-decoration: none;
      }
    }
  }
}
