h3 {
  &.satzung, 
  .satzung {
    color: color('dark-grey', $colors-greyscale);
    display: block;
    font-size: .8em;
    font-weight: bold;
    line-height: 1em;
    margin: 0;
    padding: 2em 0 0;
  }
}

.satzung {
  ul {
    list-style-image: none;
    list-style-type: disc;
    margin-left: 1.5em;
  }
}
