.banner-Wrapper_Startseite {
  background: url('/Ja-zur-Stadtbahn/img/Banner-StadtBahn-Ausbau_2014-05.jpg') 0 0 no-repeat;//../img/veranstaltungen/Header_Monheim_900x21.jpg
  display: block;
  height: 21em;
  margin-bottom: 1.5em;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.banner-Caption_Startseite {
  background-color: rgba(0, 0, 0, .7);
  height: 18em;
  left: -40em;
  padding: 1.5em 3em;
  position: absolute;
  top: 0;
  width: 34em;
}

.banner-Headline, 
.banner-Subline, 
.banner-Date, 
.banner-Place, 
.button-Banner {
  color: color('white');
  text-decoration: none;
}

.banner-Headline {
  font-family: 'OpenSansBold';
  font-size: 3em;
  line-height: 1.2em;
  margin-bottom: .4em;
}

.banner-Subline, 
.button-Place {
  line-height: 1.2em;
  margin-bottom: .4em;
}

.banner-Date {
  font-family: 'OpenSansBold';
  font-size: 2.1em;
  line-height: 1.3em;
  margin-bottom: 0;
}

.banner-Place {
  margin-bottom: 0;
}

.button-Banner {
  background: color('brand-accent-1');
  display: block;
  float: right;
  font-family: 'OpenSansBold';
  font-size: 1.4em;
  margin-bottom: 0;
  padding: .25em .4em;

  span {
    background: transparent url('../img/static/Tram-Ausstellung-2014/button-arrow-e.png') center right no-repeat;
    padding-right: 1.2em;
  }
}

