/* ==========================================================================
   Helper classes
   
   https://html5boilerplate.com/
   ========================================================================== */

/*
 * Hide visually and from screen readers:
 */

@mixin hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

@mixin visuallyhiddenFocusable {
    &:active, &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;        
    }
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

@mixin invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

@mixin clearfix {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }

    &:after {
        clear: both;
    }
    
}

// Image-replacement 
// -----------------
// Hides text for image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
@mixin hide-text {
    font: #{0 / 0} a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

// Placeholder
// -----------
// Changes Placeholder styles
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        //  Firefox 18-
        @content;
    }
    &::-moz-placeholder {
        // Firefox 19+
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}


/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

@mixin selection {
    &::-moz-selection {
        background: #b3d4fc;
        text-shadow: none;
    }

    &::selection {
        background: #b3d4fc;
        text-shadow: none;
    }    
}

// Text input
// ------------
// Calls addresses all text inputs in one selector
$text-input-types: text, password, date, datetime, datetime-local, month, week, email, number, search, tel, time, url, color !default;

@mixin text-input($types: $text-input-types) {
  $selectors: ();
  @each $type in $types {
    $selector: unquote("input[type='#{$type}']");
    $selectors: append($selectors, $selector, comma);
  }
  & #{$selectors} {
    @content;
  }
}

// Trans-prep
// ----------
// Prepares for transforms. Gives backface-visibility: hidden 
// to remove flickering effect on Webkit browsers
// - http://blog.teamtreehouse.com/increase-your-sites-performance-with-hardware-accelerated-css
@mixin trans-prep {
  // backface visibility
  backface-visibility: hidden;
}

// Z-index
// -------
@mixin zi($location) {
  z-index: map-get($zindex, $location);
}

/*
 * Emboss
 * http://sachagreif.com/useful-sass-mixins/
 * .box{
 *   @include box-emboss(0.8, 0.05);
 * }
 */
@mixin box-emboss($opacity, $opacity2){
    box-shadow:white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}