.wide {
  margin: 0 auto;
  text-align: left;
  width: 955px;
}

.outer-frame {
  background: url('/img/static/bg_outerFrame_right.png') top right repeat-y;
  position: relative;
}

.wrapper {
  background: url('/img/static/bg_wrapper_left.png') top left repeat-y;
  margin-right: 0;
  padding-left: 5px;
  position: relative;
  width: 945px;
}

* html {
  .wrapper {
    margin-right: 0;
  }
}

.outer {
  background: color('white');
  margin: 0;
  padding: 3em 0 3em 3em;
  position: relative;
  width: 91.5em;
}

.float-wrap {
  @include border-radius(5px);
  background: color('light-grey', $colors-greyscale);
  float: left;
  margin: 0 0 2em;
  outline: 0;
  padding: 2em;
  width: 67em;

  &.hasSubNavEntries {
    @include border-radius(5px 0 5px 5px);
  }
}

.right {
  float: left; 
  margin: 0;
  position: relative;
  width: 18.5em;

  .container-left {
    @include border-radius(0 5px 5px 0);
    background: color('light-grey', $colors-greyscale);
    margin: 0 0 3em;
    padding: .75em 0 1em;
  }
}
