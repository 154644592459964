.boxNahverkehrStart {
  background: url('../img/static/bg_boxTeaserStartseite_lb.gif') left bottom no-repeat;
  float: left;
  margin-right: 1em;
  width: 20em;

  .content {
    background: url('../img/static/bg_boxTeaserStartseite_r.gif') right bottom no-repeat;
    height: 12em;
    padding: .7em 1em 1em 1em;

    a {
      display: block;
      height: 12em;
      text-decoration: none;
    }
  }

  &.boxGestern {
    .content {
      a {
        background: url('/_thumbnails_/130_6_a1-moBiel-17.jpg') center center no-repeat;
      }
    }
  }

  &.boxHeute {
    .content {
      a {
        background: url('/_thumbnails_/141_6_f5-BIELEFELDER-LINIEN-2009-aktuell.jpg') center center no-repeat;
      }
    }    
  }

  &.boxMorgen {
    margin-right: 0;

    .content {
      a {
        background: url('/_thumbnails_/147_6_k1-moBiel-2030praesentation.jpg') center center no-repeat;
      }
    }
  }

  h3 {
    background: url('../img/static/bg_boxTeaserStartseite_lt.gif') left top no-repeat;
    font-size: 1.1em;
    //font-weight: normal;
    margin: 0;
    padding: 0;

    a {
      background: url('../img/static/bg_boxTeaserStartseite_rt.gif') right top no-repeat;
      display: block;
      font-size: 1.3em;
      padding: .4em 1em;
      text-decoration: none;

      span {
        background: url('../img/static/bg_nav_left_on.gif') 0 .35em no-repeat;
        display: block;
        padding-left: .75em;
      }
    }
  }
}

// Nahverkehr in Bielefeld
// -----------------------------------------------------------------------------
.nahverkehr {
  .row {
    background: color('white');
    clear: both;

    .left {
      float: left;
      padding-top: 1.5em;
      width: 19.5em;
    }

    .center {
      margin-left: 19em;
      padding-bottom: 0;
      padding-right: 2.4em;
    }

    &.fullWidth {
      .center {
        margin-left: 0;
      }

      .left {
        display: none;
      }
    }

    .imageWrapper {
      margin-bottom: 1em;
      padding-right: .5em;
      text-align: right;

      a {
        display: block;

        &.bildunterzeile {
          background: url('/img/static/zoom-in.png') top right no-repeat;
          color: color('dark-grey', $colors-greyscale);
          font-size: 1.1em;
          line-height: 1.3em;
          margin-right: -.4em;
          margin-top: .25em;
          padding: 0 2em 1em 1em;
          text-align: right;
          text-decoration: none;
        }
      }
    }

    h2 {
      clear: right;
      margin-bottom: .25em;
    }

    &.pagina {
      padding: 4em 2em 2em;

      hr {
        border: 0 none;
        border-top: 1px dotted color('medium-grey', $colors-greyscale);
        height: 1px;
        margin: 0;
        padding: 0 0 1em;
      }

      a {
        background: url('/img/static/arrow_right_10x8.png') right .3em no-repeat;
        color: color('brand-primary');
        display: block;
        float: right;
        font: 1.2em/1.2em 'OpenSansSemiBold', arial, sans-serif;
        margin: 0;
        padding: 0 1.2em 0 0;
      }

      div {
        &.back {
          float: left;

          a {
            background: url('/img/static/arrow_left_10x8.png') left .3em no-repeat;
            float: left;
            padding-left: 1.2em;
          }
        }
      }
    }

    &.last {
      padding: 2em 0;
    }
  }

  h2 {
    &.overline {
      font-size: 1.1em;

      a {
        &.active {
          color: #014784;
        }
      }
    }
  }

  p {
    &.teaser {
      color: color('medium-dark-grey', $colors-greyscale);
      font: 1.5em/1.5em 'OpenSansItalic', arial, sans-serif;
      margin-bottom: 1em;
    }
  }
}

.float-wrap {
  &.standard {
    .row {
      h2 {
        clear: right;
        margin-bottom: 1em;
      }
    }    
  }  
}
