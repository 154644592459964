
// Breakpoints Map
// ---------------
// Stores variables for all breakpoints used for the site. 
// - Uses the `bp()` mixin to output values min-width or max-with. 
// - Optionally allows for portrait or landscape orientation
// 
// See `bp()` mixin for more info. 
// ================
/*
$breakpoints: (
  'small'     : 320px,
  'small+1'   : 400px,
  'med-1'     : 560px,
  'med'       : 640px,
  'med+1'     : 700px,
  'large-1'   : 900px,
  'large'     : 1000px,
  'large+1'   : 1100px,
  'wide-1'    : 1200px,
  'wide'      : 1400px,
  'wide+1'    : 1500px,
  'mega-1'    : 1600px,
  'mega'      : 1800px,
  );
*/

$mobile-landscape: 30em;// 480px;
$tablet-small: 37.5em;// 600px;
$tablet: 48em;//768px 50em;// 800px;
$tablet-landscape: 64em;// 1024px;
$computer: 75em;// 1200px;
$computer-large: 100em;// 1600px;

$smallColumns: 8;
$mediumColumns: 9;
$largeColumns: 15;