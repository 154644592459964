// Typography mixins
// -----------------

// Font weight
// -----------
@mixin fw($weight, $target: primary) {  
    font-weight: map-fetch($font-weights, $target $weight); 
}

// Font Family
// -----------
@mixin ff($family: primary, $map: $font-families) {
    font-family: map-get($map, $family);
    font-weight: map-get($font-weight-default, $family);
    font-style: normal;
}

// Type-scale
// ----------
// Gets font-size and baseline from typography map located
// in variables/typography and creates the necessary
// font-size and line-height property in CSS with the
// compass vertical rhythm.
//
// This uses the @include adjust-font-size-to mixin and
// requires Compass Vertical Rhythm to be used.
//
// https://medium.com/p/8e51498c3713
// ==========
@mixin type-scale($scale, $map: $type-scale) {
    $font-size: map-fetch($map, $scale font-size);
    $base-lines: map-fetch($map, $scale base-lines);
    @include adjust-font-size-to($font-size, $base-lines);
}

// Text overflow
// -------------
// Requires inline-block or block for proper styling
@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*
 *
 * $dir: w(est) | e(ast) (default)
 * $pos: before | after (default)
 * $format: png | svg (default)
 * $type: list | link (default)
 *
 */
@mixin arrow($dir: e, $pos: after, $format: svg, $type: link) {
    //color: $BiProNv-Blau;        
    //text-decoration: none;

    @if $type == list {
        display: block;
        margin-left: 0.9rem;
    }
    @else {
        display: inline-block;
    }
    
    &:hover, &:focus {
        background-color: $BiProNv-Blau;
        color: #fff;
    }
    
    @if $pos == before {
        &:before {
            @include arrowPseudo($dir, $format, $type);
        }

        @if $type == link {
            &:hover, &:focus {
                &:before {
                    @if $dir == w {
                        background-position: -1.4em -4.15em;// Wechsel auf weißen Pfeil
                    }
                    @else {
                        background-position: 0 -4.15em;// Wechsel auf weißen Pfeil
                    }
                }
            }
        }
    }
    @else {
        &:after {
            @include arrowPseudo($dir, $format, $type);
        }

        @if $type == link {
            &:hover, &:focus {
                &:after {
                    @if $dir == w {
                        background-position: -1.4em -4.15em;// Wechsel auf weißen Pfeil
                    }
                    @else {
                        background-position: 0 -4.15em;// Wechsel auf weißen Pfeil
                    }
                }
            }
        }
    }
}

@mixin arrowPseudo($dir: right, $format: svg, $type: link) {
    content: "";
    display: inline-block;
    
    @if $type == list {
        height: 1rem;
        width: 0.6rem;
        margin-left: -0.9rem;
        margin-right: 0.3rem;
        background-size: auto 272%;
    }
    @else {
        height: 1em;
        width: 1.1em;
        background-size: auto 5em;//384%;        
    }
    background-repeat: no-repeat;
    vertical-align: sub;

    @if $format == png {
        background-image: url("/img_static/icon-arrow-sprite.png");
    }
    @else {
        background-image: url("/img_static/icon-arrow-sprite.svg");
    }
    @if $dir == w {
        @if $type == list {
            background-position: -1.4rem -1.25rem;
        }
        @else {
            background-position: -1.4em -1.4em;
        }
    }
    @else {
        @if $type == list {
            background-position: 0 -1.25rem;
        }
        @else {
           background-position: 0 -1.4em;
        }
    }
}