.itemBox {
  &.start {
    border-right: 1px dotted color('medium-light-grey', $colors-greyscale);
    float: left;
    height: 10em;
    padding: 0 2em;

    &.box1 {
      border-left: 0 none;
      padding-left: 0;
      width: 19em;
    }

    &.box2 {
      width: 36em;
    }

    &.box3 {
      border: 0 none;
      float: right;
      margin-right: 1.5em;
      padding: 0 0 0 2em;
      width: 26.5em;

      p {
        margin-top: 2.3em;
      }
    }

    a {
      &.intern {
        font-size: 1.1em;
        line-height: 1.45em;
      }
    }
  }
  
  h1 {
    color: color('dark-grey', $colors-greyscale);
    font-family: 'OpenSansSemibold', arial, sans-serif;
    font-size: 1.1em;
    margin: 0 0 .8em 0;
    padding: 0;
  }

  div.thumb {
    float: left;
    margin-right: 1.2em;
  }

  p, 
  ul {
    color: color('medium-dark-grey', $colors-greyscale);
    font-size: 1.1em;
    line-height: 1.45em;
    margin: 0;
    padding: 0;
  }


  ul {
    margin-left: 1.6em;

    li {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
  }

  p {
    a {
      color: color('medium-dark-grey', $colors-greyscale);
      text-decoration: none;
    }
  }
}
