.vorstand {
  margin-bottom: 1em;

  .bildVorstand {
    float: left;
    width: 13em;
  }

  .textVorstand {
    margin-left: 15em;

    .name {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 0;
    }

    .beruf {
      color: color('medium-dark-grey', $colors-greyscale);
      line-height: 1.2em;
    }

    .funktion {
      font-style: italic;
    }
  }
}
