.links {
  background: color('white');
  padding: 0 2em;

  &.first {
    padding: 2em 0 0;
  }

  .item {
    border-top: 1px dotted color('medium-grey', $colors-greyscale);
    padding: 2em 0;
  }

  .left {
    float: left;
    padding-top: .5em;
    width: 19.5em;
  }

  .center {
    margin-left: 19em;
    padding-bottom: 0;
    padding-top: 0;
  }

  .imageWrapper {
    margin-right: 1em;
    text-align: right;
  }

  ul {
    list-style-image: none;
    margin-left: 0;

    li {
      margin-bottom: 1em;
    }
  }
}
