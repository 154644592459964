#nav {
  ul {
    font-size: 1.2em;
    list-style-image: none;
    list-style-type: none;
    margin: 0;

    li {
      margin-bottom: 0;
    }

    a {
      border-bottom: 1px solid #e0e0e0;
      color: color('dark-grey', $colors-greyscale);
      display: block;
      line-height: 1.5em;
      padding: .75em 0;
      text-align: right;
      text-decoration: none;

      &.last {
        border-bottom: 0 none;
      }

      &.nav_off:hover, 
      &.nav_on, 
      &.nav_down {
        background: url('../img/static/bg_nav_left_on.gif') right 1.2em no-repeat;
        color: #054a86;
        font-weight: bold;
        padding-right: 1em;
      }

      &.nav_down {
        background: url('../img/static/bg_nav_left_down.gif') right 1.3em no-repeat;
        padding-right: 1em;
      }
    }

    ul {
      font-size: .9em;

      a {
        margin-right: 1em;
        padding: .5em 1em .5em 0;

        &.nav_off:hover, 
        &.nav_on {
          background-position: right 1em;
        } 
      }
    }
  }
}

.right {
  #nav {
    ul {
      a {
        text-align: left;

        &.nav_off:hover, 
        &.nav_on, 
        &.nav_down {
          background-position: left 1.2em;
          padding-left: 1em;
        }

        &.nav_down {
          background-position: left 1.3em;
          padding-left: 1em;
        }
      }

      ul {
        a {
          margin-left: 1em;
          padding: .5em 0 .5em 1em;

          &.nav_off:hover, 
          &.nav_on {
            background-position: left 1em;
          }
        }
        
        ul {
          margin-left: 1em;
        }
      }
    }    
  }
}
